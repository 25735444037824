export default {
    
    collection: {
        snapshot: true, // watch current page items

        label: 'event_guests',
        desc:'guests_desc',
        collectionPath (vm) {
            return '/users/'+vm.currentUser.uid+'/guests'
        },
        header: vm => [
            {
               component: () => import('vuetify/lib/components/VBtn'),
               attrs: {
                   rounded: true,
                   depressed: true,
                   color: 'success',
             
                   to: '/dashboard/guests/new'
               },
               slots: {
                   default: [
                       {
                           component: () => import('vuetify/lib/components/VIcon'),
                           attrs: {
                            class: 'mx-1'
                           },
                           slots: {
                               default: [
                                   'mdi-account-plus'
                               ]
                           }
                       },
                       vm.breakpoint.xs ? '_' : 'add_guest'

                   ]
               }
            },
            {
                component: () => import('vuetify/lib/components/VGrid/VSpacer'),
                slots: {
                    default: ['']
                }
            },
            {
                component: () => import('vuetify/lib/components/VBtn'),
                attrs: {
                    rounded: true,
                    depressed: true,
                    color: 'info',
                    class: 'mx-1'
                },
                
                on: (vm) => ({
                    click() {
                      
                        const getGuests = (data, map) => data.map(row => {
                            const guest = {
                                status: 'pendding',
                                smsStatus: 'not_sent',
                                table: null,
                                uid: vm.currentUser.uid,
                                createdAt: new Date()
                            }
                            for (const key in map)
                                if (Object.hasOwnProperty.call(map, key)) {
                                    //clean && fix data
                                    const field = vm.importFields.find(e => e.value == map[key])
                                    if (field && field.type) {
                                        if (row[key] === undefined)
                                            row[key] = field.default || field.type();
                                        
                                        if (typeof row[key] !== typeof field.type())  
                                           row[key] = field.type(row[key]);
                                    }

                                    guest[map[key]] = row[key]
                                }
                            return guest
                        })

                         

                        vm.openDialog('excel', { title: 'import_guests', icon: 'mdi-microsoft-excel', mapFields: vm.importFields }, async ({ data, map, dialog }) => {
                        
                            let imported = 0
                            const batch_size = 500
                            const batchs = Math.ceil(data.length / batch_size)

                            dialog.progress = 0
                            const run_batch = (batch_number) => {
                                const items = data.slice(batch_number * batch_size, (batch_number * batch_size) + batch_size)
                                const batch_items = getGuests(items, map)
                                const batch = vm.database.batch()
                                
                                batch_items.forEach(docData => {
                                    const doc = vm.collectionRef.doc()
                                    // add to batch                                            
                                    batch.set(doc, { ...docData })
                                })

                                return batch.commit().then(() => {
                                    dialog.progress = ((batch_number + 1) / batchs) * 100
                                    imported += batch_items.length
                                   

                                }).catch(vm.handelError)
                                
                                
                               
                            }
                            vm.dialogLoading(true)
                            for (let index = 0; index < batchs; index++) {
                                await run_batch(index)
                            }
                            vm.updateView()
                            vm.dialogLoading(false)
                            vm.closeDialog()
                            vm.notify(vm.$t(`import_file_successfuly`, imported), {
                                color: 'success',
                                icon: 'mdi-check-circle'
                            })
 

                        }, { maxWidth: 650, scrollable:true })

                    }
                }),
                slots: {
                    default: [
                        {
                            component: () => import('vuetify/lib/components/VIcon'),
                            attrs: {
                                class: 'mx-1'
                            },
                            slots: {
                                default: [
                                    'mdi-microsoft-excel'
                                ]
                            }
                        },
                        vm.breakpoint.xs ? '_' : 'import_guests'

                    ]
                }
            },
            {
                component: () => import('vuetify/lib/components/VBtn'),
                attrs: {
                    rounded: true,
                    depressed: true,
                    color: 'info',
                    class: 'mx-1'
                },

                on: (vm) => ({
                    click() {
                        vm.showSelect = true
                        const confirm = async () => {
                            const { all, selected } = vm
                            const eventDoc = await vm.database.collection('event').doc(vm.currentUser.uid).get()
                            const event = eventDoc.data()
                            let { me, partner, name, type } = event
                            event.name = name || me + ' ♥ ' + partner
                            event.event_name = vm.$t(type)
                            event.date = vm.$options.filters.date(event.date.toDate())
                          

                             
                            vm.selectedLoading = true
                                vm.$api.post('/sms', {
                                    all, selected, event
                                }).then((res) => {
                                    console.log(res);
                                    vm.showSelect = false
                                    vm.notify(vm.$t(`sms_is_sending`), {
                                        color: 'success',
                                        icon: 'mdi-check-circle'
                                    })
                                }).catch(err => {
                                    vm.handelError(err)
                                    vm.$once('confirm', confirm)
                                }).finally(() => vm.selectedLoading = false)
                         
                        }
                        vm.$once('confirm', confirm)


                    }
                }),
                slots: {
                    default: [
                        {
                            component: () => import('vuetify/lib/components/VIcon'),
                            attrs: {
                                class: 'mx-1'
                            },
                            slots: {
                                default: [
                                    'mdi-send'
                                ]
                            }
                        },
                       
                        vm.breakpoint.xs ? '_' :  'send_invitations'
                    ]
                }
            }
        ],
        table: {
            importFields: [
                {
                    text: 'firstname',
                    value: 'firstname',
                    type: String,
                    required: true,
                },
                {
                    text: 'lastname',
                    value: 'lastname',
                    type: String,
                    required: true,

                },
                { text: 'phone', value: 'phoneNumber',
                    type: String,
                    required: true,
                },
                { text: 'guests_number', value: 'guests', default: 1, type: Number },
            ],
            headers: [
                {
                    text: 'firstname',
                    value: 'firstname',
                    align: 'start',
                },
                {
                    text: 'lastname',
                    value: 'lastname',
                    align: 'start',
                },
                { text: 'phone', value: 'phoneNumber' },
                { text: 'guests_number', value: 'guests' },
                { text: 'table', value: 'table.name' },
                { text: 'guest_confirm_comming', value: 'status', component: () => import('@/components/cells/Status') },
                {
                    text: 'sms_status',
                    value: 'smsStatus',
                    component: () => import('@/components/cells/SMS')

                },
                {
                    text: 'created',
                    value: 'createdAt',
                    align: 'start',
                    format: 'now'

                },

                { value: 'settings', sortable:false }
            ],
            actionBtns(vm) {

                return [
                    {
                        icon: 'mdi-circle-edit-outline',
                        color: 'primary',
                        label: 'edit',
                        to(item) {
                            return '/dashboard/guests/' + item.id
                        }

                    },
                    {
                        icon: 'mdi-delete',
                        color: 'error',
                        label: 'delete',
                        on: (item) => ({
                            click() {
                                vm.openDialog('confirm', {
                                    title: 'confirm_guest_deletion',
                                    text: 'confirm_guest_deletion_text',
                                    name: item.firstname + ' ' + item.lastname,
                                    confirmText: 'delete_guest',
                                    confirmIcon: 'mdi-delete',
                                    color: 'error'
                                }, () => {
                                    const { id } = item
                                    vm.dialogLoading(true)
                                    vm.collectionRef.doc(id).delete()
                                        .then(() => {
                                            vm.notify(vm.$t('guest_deleted_successfuly', item.firstname + ' ' + item.lastname), {
                                                color: 'success',
                                                icon: 'mdi-check-circle'
                                            })
                                            vm.updateView()
                                            vm.dialogLoading(false)
                                            vm.closeDialog()
                                        }).catch(vm.handelError).finally(() => vm.dialogLoading(false))

                                })
                            }
                        })

                    }
                ]
            }
            
        }
    },

    document: {
        collectionName: 'event_guests',
        newDocName: 'add_guest',
        nameField (item) {
            return item.firstname + " " + item.lastname
        },
        fields (vm) {
            const { $t: __ } = vm 
            return [
                {
                    name: 'firstname',
                    input: () => import('vuetify/lib/components/VTextField'),
                    attrs: {
                        label: __('firstname'),
                        startIcon: 'mdi-account',
                        required: true,
                        rules: vm.getFieldRule('required'),
                    }
                },
                {
                    name: 'lastname',
                    input: () => import('vuetify/lib/components/VTextField'),
                    attrs: {
                        label: __('lastname'),
                        startIcon: 'mdi-account',
                        required: true,
                        rules: vm.getFieldRule('required'),
                    }
                },
                {
                    name: 'phoneNumber',
                    input: () => import('vuetify/lib/components/VTextField'),
                    attrs: {
                        label: __('phone'),
                        startIcon: 'mdi-phone',
                        required: true,
                        rules: vm.getFieldRule('required'),
                    }
                },
                {
                    name: 'guests',
                    input: () => import('vuetify/lib/components/VTextField'),
                  

                    attrs: {
                        value: 1,
                        label: __('guests_number'),
                        startIcon: 'mdi-plus',
                        endIcon: 'mdi-minus',
                        type: 'number',
                        required: true,
                        rules: vm.getFieldRule('required'),
                    },
                    on: formVm => ({
                        startIcon: () => {
                            formVm.values.guests++
                        },
                        endIcon: () => {
                            if (formVm.values.guests > 1)
                                formVm.values.guests--
                            else formVm.values.guests = 1

                        }
                    })
                },
                // {
                //     name: 'table',
                //     input: () => import('@/components/VSearch'),
                //     attrs: {
                //         path: '/users/' + vm.currentUser.uid + '/tables',
                //         label: __('table'),
                //         startIcon: 'mdi-table-chair',
                //     }
                // },
                {
                    name: 'status',
                    attrs: {
                        value: 'pendding',
                    }
                },
                {
                    name: 'smsStatus',
                    attrs: {
                        value: 'not_sent'
                    }
                }
            ]
        }
        
    }

    


}