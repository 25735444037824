export default {
    collection: {
        label: 'events',
        collectionPath () {
            return 'event'
        },
        header: [],
        table: {
      
            headers: [
                { text: 'user', value: 'user', component: () => import('./../components/cells/User') },
                {
                    text: 'event_type',
                    value: 'type',
                    format: 't'
                },
                {
                    text: 'date',
                    value: 'date',
                    format: 'date'

                },

            ],
            actionBtns() {

                return []
            }
            
        }
    },

    document: {
        collectionName: 'my_events',
        newDocName: 'add_event',
        nameField (item) {
            return item.type
        },
        fields () {
            
            return []
        }
        
    }

    


}