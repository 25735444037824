<template>
 <v-layout id="collection-container" class="fh" column   >
    <v-flex  class="px-6 py-2 headline primary--text font-weight-bold" shrink  v-if="!showSelect">{{ collectionSettings.label | t }}  </v-flex>
           
    <v-flex class=" d-flex " shrink v-if="showSelect">
        <v-alert dark color="info" class="ma-0" tile width="100%">
            {{'invitations_desc' | t}}
            <br>
            <small v-html="$t('send_messages_to', all ? pagination.itemsLength : selected.length,  pagination.itemsLength)">
            </small>
            <br>
            <v-checkbox :disabled="selectedLoading" :label="$t('all_guests')" v-model="all" ></v-checkbox>
            
            <v-btn rounded light   class="me-3" @click="confirmSelected" :loading="selectedLoading" >{{ 'send' | t }}</v-btn>
            <v-btn  rounded light @click="showSelect=false" :disabled="selectedLoading">{{ 'cancel' | t }}</v-btn>
        </v-alert>
    </v-flex>
    <v-flex class=" px-6 d-flex py-2" shrink v-else>
            <component 
                v-for="(head, n) in header" :key="n" 
                :is="head.component"
                v-bind="head.attrs"
                v-on="head.on"
            >
            
                <template
                    v-for="(slotName, i) in Object.keys(head.slots)"
                    v-slot:[slotName]
                >
                    <template
                        
                        v-for="(component, ii) in head.slots[slotName]"
                    >
                        <span :key="i+'-'+ii" v-if="typeof component == 'string'">
                            {{component | t}}
                        </span>
                        <component 
                            v-else
                            :key="i+'-'+ii"
                            :is="component.component"
                            v-bind="component.attrs"
                            v-on="component.on"
                        >
                            <template
                                v-for="(componentSlotName) in Object.keys(component.slots)"
                                v-slot:[componentSlotName]
                            >
                                <template
                                    v-for="(componentSlot) in component.slots[componentSlotName]"
                                >
                                    {{componentSlot}}
                                    
                                </template>
                            </template>
                        </component>
                    </template>
                </template>
            </component>
    </v-flex>
    <v-divider ></v-divider>
    <v-flex grow  class=" relative " ref="wraper">
        <div class="fh  absolute overflow-y-auto"  style="top:0; left:0; width: 100%;"   >
            <v-data-table ref="table"
                :show-select="showSelect && !all"
                v-model="selected"
                
                :loading="loading"
                :headers="headers"
                fixed-header
                :height="tableHeight"
                :items="documents"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                :options="options"
                :server-items-length="pagination.itemsLength"
                :items-per-page-options="footerProps['items-per-page-options']"
                @update:options="resetPage" 
            >
             <template v-for="(header,n) in headers" v-slot:[`item.${header.value}`]="props">
                 
                <component :key="n" v-if="header.component" :is="header.component" :props="props" :field="getField(props.header.value)"></component> 
                <span :key="n" v-else v-html="getValue(props)"></span>
             </template>
             <template v-slot:loading>
                <v-skeleton-loader
                class="my-2"
                  :type=" breakpoint.smAndUp ? 'table-tbody' : 'card, card'"
                  :width="breakpoint.smAndUp ? null : breakpoint.width - 33"
                ></v-skeleton-loader>
              </template>

            <template v-slot:item.settings="{ item }">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-subheader>
                      {{ 'settings' | t }}
                    </v-subheader>
                    <v-list-item
                      v-for="(btn, n) in actionBtns" :key="n" 
                      v-on="btn.on ? btn.on(item) : {}"
                      :to="btn.to ? btn.to(item) : null"
                      
                      
                    >
                   
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon small v-if="typeof btn.icon == 'function' ? btn.icon(item) : btn.icon" :color="typeof btn.color == 'function' ? btn.color(item) : btn.color" class="mx-1">{{typeof btn.icon == 'function' ? btn.icon(item) : btn.icon}}</v-icon>
                          {{ typeof btn.label == 'function' ? btn.label(item) : btn.label | t }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
 
             </template>
            </v-data-table>
        </div>
     </v-flex>
      <v-divider></v-divider>
     <v-flex shrink class="bar" >
           <v-data-footer
          :class="{ mobile: !breakpoint.smAndUp }"
          :options.sync="options"
          :pagination="pagination"
          :items-per-page-options="footerProps['items-per-page-options']"
          
        > </v-data-footer>
          <!-- @update:options="paginate"  -->
     </v-flex>

 </v-layout>
</template>

<script>
const compareObject = function (object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) 
        return false;
    

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !compareObject(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

  return true;
}
const isObject = function (object) {
  return object != null && typeof object === 'object';
}

import CreationTime from '@/components/cells/CreationTime'

export default {
    name: 'Collection',
    components: {
        CreationTime
    },
    watch: {
        '$route': {
            handler() {
                this.resetPage()
                this.updateView()
            },
        },
        showSelect: {
            handler(showSelect) {
                this.all = showSelect
                this.calcContainerHeight()
            },
        },
        breakpoint:{ 
            handler () {
                this.calcContainerHeight()
            },
            deep: true
        },
        options: {
            handler: 'updateView',
            immediate: true
        }
    },
    data () {
        return {
            all: false,
            selected: [],
            selectedLoading:false,
            showSelect: false,
            snapshots: [],
            pageCountReset: false,
            //for pagination
            lastDoc: null,
            firstDoc: null, 
            tableHeight:0,
            loading:false,
            documents: [],
            prevOptions:{
                page: 0,
                itemsPerPage: 5,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },
            options: {
                page: 1,
                itemsPerPage: 5,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },
            pagination: {
                page: 1,
                itemsPerPage: 5,
                get pageStart() {
                    return (this.page - 1) * this.itemsPerPage
                },
                get pageStop() {
                    return (this.page * this.itemsPerPage) 
                },
                get pageCount() {
                    return Math.ceil(this.itemsLength / this.itemsPerPage) 
                },
                itemsLength: 0
            }
        }
    },
    computed: {
         
        params () {
            return this.$route.params
        },
        collection () {
            return this.params.collection
        },
        collectionRef () {
            return this.database.collection(this.collectionSettings.collectionPath(this))
        },
        settings (){
            let settings
            try {
                const collection = require('@/collections/' + this.collection)
                if (collection)
                    settings = collection.default
            } catch (e) {
                this.$router.replace('/404')
            }
            return settings
        },
        collectionSettings () {
            
            return this.settings ? this.settings.collection : undefined
        },
        footerProps () {
            let limits = [5, 10, 25, 50, 100]
            const qLimit = Number(this.$route.query.limit)
            if(!isNaN(qLimit) && limits.indexOf(qLimit) == -1)
                limits.push(qLimit)
            return {'items-per-page-options':  limits}
        },
        importFields () {
            if (!this.collectionSettings) return []
             return this.collectionSettings.table.importFields.map(field => {
                field = {...field}
                field.text = field.text ? this.$t(field.text) : undefined
                return field
            })
        },
        headers () {
            if (!this.collectionSettings) return []
            let { headers } = this.collectionSettings.table
 
            if (typeof headers == 'function')
                headers = headers(this)
            
            
            return headers.map(head => {
                head = {...head}
                head.text = head.text ? this.$t(head.text) : undefined
                return head
            })
        },
        header () {
            if (!this.collectionSettings) return []
            let { header } = this.collectionSettings
            
            if (typeof header == 'function')
                header = header(this)
            return header.map(btn => {
                btn = { ...btn }
                btn.on = typeof btn.on == 'function' ? btn.on(this) : btn.on
                return btn
            })
        },
        actionBtns() {
             if (!this.collectionSettings) return []
             return this.collectionSettings.table.actionBtns(this)
        }

    },
    methods: {
        getField(name) {
            return this.settings.document.fields(this).find(e => e.name == name)
        },
        getValue (props) {
           if (typeof props.header.get == 'function')
                props.value = props.header.get(props)

            if (props.header.args)
                props.header.args = props.header.args.map(arg => {
                    const fc = arg.substr(0, 1)
                    if (fc == '$')
                        return props.item[arg.substr(1)]
                    return arg
                })


            if(props.header.format && this.$options.filters[props.header.format])
                return this.$options.filters[props.header.format](props.value, ...(props.header.args || []))
            return props.value
        },
       
        async collectionRefQuery()  {
            let collectionRef = this.collectionRef
             
            const { sortBy, sortDesc } = this.options
            const doc = await this.database.collection('users').doc(this.currentUser.uid).get()
            const docData = doc.data()
 

            this.pagination.itemsLength = docData && docData[this.collection] ? docData[this.collection] : 1000
            if (sortBy && sortBy.length) {
                sortBy.forEach((field, index) => {
                    collectionRef = collectionRef.orderBy(field, sortDesc[index] ? 'desc' : 'asc')
                } )
            } else
                collectionRef = collectionRef.orderBy(this.collection == 'events' ? 'date' : 'createdAt')




            return collectionRef
        },
        calcContainerHeight () {
            setTimeout(() => {
                const { wraper } = this.$refs
                this.tableHeight = wraper.clientHeight 
            }, 50)
        },
        resetPage (options) {
            options = options || this.options
            this.pageCountReset = true
            this.options = options
        },
        async updateView (options, prevOptions) {
            this.documents = []
            options = options || this.options
            prevOptions = prevOptions || this.prevOptions

            if (compareObject(options, prevOptions)) return 

            if (this.pageCountReset) {
                prevOptions.page = 0
                options.page =  1
                this.pagination.page =  1
            }
            const { page, itemsPerPage } = options
            const { page: prevPage } = prevOptions
            
            this.pagination.page = page
            this.pagination.itemsPerPage = itemsPerPage
            let ref = await this.collectionRefQuery()
  
            if(prevPage > page) {
                ref = ref.endBefore(this.firstDoc)
                ref = ref.limitToLast(itemsPerPage);
            } else {
                if (page > 1)
                    ref = ref.startAfter(this.lastDoc)
                ref = ref.limit(itemsPerPage)
            }
            
    
            
            this.loading = true
       
         
            ref.get().then(ref => {
                this.lastDoc = ref.docs[ref.docs.length-1]
                this.firstDoc = ref.docs[0]
                this.documents = ref.docs.map(doc => ({ id: doc.id, ...doc.data() }))
              
                // watch current page items
                if (this.settings.collection.snapshot ) {
                    this.clearSnapshots()
                    ref.docs.forEach(doc => {
                        const unsub = doc.ref.onSnapshot(snapshot => {
                            const { id, exists } = snapshot
                            const index = this.documents.findIndex(e => e.id == id)
                           
                            // doc added
                            if (index === -1 && exists)
                                this.documents.push({ id, ...snapshot.data() })

                            // doc edited
                            if (index !== -1 && exists)
                                 this.documents.splice(index,1, { id, ...snapshot.data() })

                            // doc removed
                            if (index !== -1 && !exists)
                                this.documents.splice(index,1)

                        })
                        this.snapshots.push(unsub)
                    })
                    
                } 
               
            }).catch(this.handelError).finally(() =>{
                  
                 this.loading = false
                 this.pageCountReset = false
            })
        },
        clearSnapshots () {
            if (!this.snapshots.length) return 
            while(this.snapshots.length) {
                const unsub = this.snapshots.pop()
                unsub()
            }

        },
        confirmSelected () {
            this.$emit('confirm')
        }
       

    },
    mounted () {
        this.calcContainerHeight()
    },
    beforeDestroy() {
        this.clearSnapshots()
    }
}
</script>

<style lang="scss">

    #collection-container {
        background: transparent;
        .bar {
            flex-basis: 58px;
            max-height: 58px;
            min-height: 58px;
        }
        .head-bar {
            flex-basis: 110px;
            max-height: 110px;
            min-height: 110px;
        }
        .v-data-footer__pagination{
            margin: 0 24px 0 auto  !important;
        }
       .v-data-footer__select {
            margin-left: 0 !important;
            margin-right: 14px !important;
        }
    }
</style>